import React, { createRef, useEffect, useLayoutEffect, useState } from 'react'

import 'twin.macro'
import { ResizeObserver } from '@juggle/resize-observer'

import SEO from 'components/shared/seo'
import fireEvent from 'context/tracking/events'
import { BottomCTA } from 'mx/bottom-cta'
import { CatalogPopup } from 'mx/catalog-popup'
import { SectionDetails } from 'mx/cx/category/section-details'
import { Features } from 'mx/cx/intro/features'
import { Fronts } from 'mx/cx/intro/fronts'
import { Handles } from 'mx/cx/intro/handles'
import { HardwareSection } from 'mx/cx/intro/hardware-section'
import { AltHero } from 'mx/cx/intro/hero'
import { Navigation } from 'mx/cx/intro/navigation'
import Layout from 'mx/layout'
import { QualityPopup } from 'mx/quality-popup'
import { Section } from 'mx/section'
import { FormContactSource } from 'types/crm'
import { resourcesUrls } from 'views/utils'

const Cx = () => {
  const [openCatalogPopup, setOpenCatalogPopup] = React.useState(false)
  const [openQualityPopup, setOpenQualityPopup] = React.useState(false)
  const [stickyTop, setStickyTop] = useState(0)
  const navigationRef = createRef<HTMLHeadElement>()

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM Catalog',
    })
  }, [])

  useLayoutEffect(() => {
    if (
      typeof navigationRef !== 'object' ||
      navigationRef === null ||
      !(navigationRef.current instanceof Element)
    ) {
      return
    }
    const element = navigationRef.current
    const ro = new ResizeObserver(() => {
      setStickyTop(navigationRef.current?.offsetHeight as number)
    })
    ro.observe(element)
    return () => {
      ro.unobserve(element)
      ro.disconnect()
    }
  }, [navigationRef])

  return (
    <div tw="relative">
      <Layout navigationRef={navigationRef}>
        <AltHero action={setOpenCatalogPopup} />
        <Navigation stickyTop={stickyTop} />
        <div tw="border-b border-gray-100" />
        <Section id="fronts" tw="">
          <Fronts />
        </Section>
        <div tw="border-b border-gray-100" />
        <Section id="handles" tw="" padding="top">
          <Handles />
        </Section>
        <Section id="features" tw="" padding="top">
          <Features />
        </Section>
        <HardwareSection />
        <SectionDetails
          setCatalogPopupStatus={setOpenCatalogPopup}
          setQualityPopupStatus={setOpenQualityPopup}
        />
        <Section padding="top">
          <BottomCTA />
        </Section>
        <div tw="h-16 md:(h-24)" />
      </Layout>
      <CatalogPopup
        contactSource={FormContactSource.CATALOG}
        onCloseModal={() => {
          setOpenCatalogPopup(false)
        }}
        onSubmit={() => {
          setOpenCatalogPopup(false)
          window.open(resourcesUrls.catalog)
        }}
        openModal={openCatalogPopup}
      />
      <QualityPopup
        contactSource={FormContactSource.CATALOG}
        onCloseModal={() => {
          setOpenQualityPopup(false)
        }}
        onSubmit={() => {
          setOpenQualityPopup(false)
          window.open(resourcesUrls.technicalGuide)
        }}
        openModal={openQualityPopup}
      />
    </div>
  )
}

export default Cx

export const Head = () => (
  <SEO
    description="Browse over 70 cabinetry fronts, handles, and features. Download our catalog to explore all design options available."
    path="/catalog"
    title="Catalog"
  />
)
